import img1 from "../../../assets/images/auth_bg.jpeg"
import img2 from "../../../assets/images/rems.jpg"

import Modal from 'react-bootstrap/Modal';
import {Link } from 'react-router-dom';
import { useState } from "react";

const SliderCarousel = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
       show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Registration Guide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul class="list-unstyled">
            <li class="mb-4">
              <h6>
                1. We advise you register with a valid email address
              </h6>
            </li>
            <li class="mb-4">
              <h6>
                2. You will provide your names and courses of choice
                (1st Choice and 2nd Choice)
              </h6>
            </li>
            <li class="mb-4">
              <h6 class="">
                3. Select programme type i.e HND or ND
              </h6>
            </li>
            <li class="mb-4">
              <h6 class="">
                4. Select study mode i.e Part-time or Full-time
              </h6>
            </li>
            <li class="mb-4">
              <h6>
                5. Select Entry mode i.e Direct Entry or UTME
              </h6>
            </li>
            <li class="mb-4">
              <h6>
                6. After Registration, check your email for your password
              </h6>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <h1 className="text-uppercase black font-weight-bold dspg-outline text-center">Apply for admission</h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h6 className="black text-center">Apply for Undergraduate Admission 2023/2024 Session Ongoing</h6>
            <h6  onClick={handleShow} className="font-weight-normal text-danger">Application Procedure</h6>
            <a href="/#">
            <button className="mt-5 w-20 btn btn-grad waves-effect">Click to Begin</button>
            </a>
          </div>
        </div>
        <div className="carousel-item">
          <h1 className="text-uppercase black font-weight-bold dspg-outline text-center">Welcome</h1>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h6 className="w-60 text-center black">As you work hard to achieve your educational 
              goals, remember that it is all worth it - because 
              you’re earning a professional degree that can 
              open doors for you in the future.</h6>
            <h6  onClick={handleShow} className="font-weight-normal text-danger">Application Procedure</h6>
            <div className="mt-4 d-flex">
            <Link to="apply/login">
              <button className="btn btn-grad waves-effect">Login/Register</button>
            </Link>
            <button className="btn btn-green-grad waves-effect">Check Admission</button>
            </div>
          </div>
        </div>
      </div>
      <a className="carousel-control-prev" style={{ 'position': 'fixed', top: '41%'}}
         href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" style={{'right': '40%','position': 'fixed','top': '41%',}}
        href="#carouselExampleIndicators" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  )
}

export default SliderCarousel