import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ApplicationLandingPage from "./pages/application/ApplicationLandingPage";

function App() {
  return (
    <div className="App">
      <ToastContainer />
        <Routes>
          {/* Landing page   */}
          <Route exact path="/" element={<ApplicationLandingPage/>}/>
        </Routes>
    </div>
  );
}

export default App;
